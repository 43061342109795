import React, { useEffect, useState } from "react";
import { Interweave } from "interweave";
import axios from "axios";

function AboutUs() {
  const [data, setData] = useState();
  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // +++++++++++++++++++++++++++++
  const getdata = () => {
    axios.get(baseUrl + `api/term/condition/About-Us`).then((res) => {
      setData(res.data[0].Desc);
    });
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div className="leftContainer mb_space">
      <div className="pt-5 mt-5  Orher_page_main_section">
        <h1 className="profile_headings">About Us</h1>
        <div>
          skillbatting skillbattingludo , is a real-time online
          LUDO card game which fully owned by skillbatting.
          We are a passionate team of designers and developers with years
          experience in the card game industry. Our aim is to provide you an
          awesome online experience on this sophisticated handmade product!
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
